/* You can add global styles to this file, and also import other style files */
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1)
}
.-oneX-bold{
    font-weight: 400;
    color: #2D2929;
    letter-spacing: 0;
    line-height: 21px;
    font-size: 16px;
    text-align: left;
    font-family: "MecherleSans-SemiBold";
}
.-oneX-header{
    font-weight: 400;
    color: #2D2929;
    letter-spacing: 0;
    line-height: 21px;
    font-size: 16px;
    text-align: left;
    font-family: "MecherleSans-Regular";
    font-style: italic;
}
.-oneX-details{
    font-size: 16px;
    color: #2D2929;
    letter-spacing: 0;
    line-height: 20px; 
    text-align: left;
    font-family: "MecherleSans-Regular",Arial;
    white-space: normal;
    padding: 2px
}
.-oneX-info{
  font-size: 14px;
  line-height: 21px;
  color: #767676 !important;
  font-weight: 400 !important;
  font-family: "MecherleSans-Regular";

}
.-oneX-th--col{
    font-size: 16px !important;
    /* overflow-x: auto; */
    }
    
    .-oneX-notification-styling{
      box-shadow: 3.5px 3.5px #f1f1f1;
      transition: transform .4s;
      border-top: #eae8e8 solid 1px;
      border-left: #eae8e8 solid 1px;
      border-bottom: #eae8e8 solid 1px;
      border-right: #eae8e8 solid 1px;
      border-radius: 7px;
      width: fit-content;
    }
.-oneX-td{
    font-size: 16px !important;
    color: #2D2929 !important;
}